var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("公司荣誉")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Company Honors")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("公司榮譽")]) : _vm._e()]), _c("ul", {
    staticClass: "qulificationCertificate"
  }, _vm._l(_vm.honor, function (items, index) {
    return _c("li", [_c("img", {
      attrs: {
        src: _vm.title + items.honorPic
      }
    })]);
  }), 0)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/company_honor.jpg")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };